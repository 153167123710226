<template>
    <div>
          <div ref="echaters" style="width:90px;height:75px;"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import 'echarts-liquidfill'
export default {
    data(){
        return{
            echinth:null,

        }
    },
   created(){

   },
   mounted(){
        this.echartsinit();


   },
   computed:{
   },
   methods:{
    //    创建echarts对象
    echartsinit(data){
        this.echinth = echarts.init((this.$refs.echaters))
        this.getdata(data);
    },
    // 请求数据
    getdata(data){

        this.upecharts(data)
    },

    //初始化对象
    upecharts(data){
      if(!data){
        return
      }
 let option = {
    series: [
        {
            type: 'liquidFill', //水位图
            radius: '80%', //显示比例
            center: ['50%', '50%'], //中心点
            amplitude: 10, //水波振幅
            data: data.dataarr, // data个数代表波浪数
            color: [
                {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: '#446bf5',
                        },
                        {
                            offset: 1,
                            color: '#2ca3e2',
                        },
                    ],
                    globalCoord: false,
                },
            ], //波浪颜色
            backgroundStyle: {
                borderWidth: 1, //外边框
                // borderColor: '#23cc72', //边框颜色
                color: 'RGBA(51, 66, 127, 0.7)', //边框内部填充部分颜色
            },
            label: {
                //标签设置
                position: ['50%', '45%'],
                formatter: data.value +'%', //显示文本,
                textStyle: {
                    fontSize: '12px', //文本字号,
                    color: '#fff',
                },
            },
            outline: {
                // show: false
                borderDistance: 0,
                itemStyle: {
                    borderWidth: 2,
                    borderColor: '#112165',
                },
            },
        },
    ],
};

        this.echinth.setOption(option, true)


        },

         getCirlPoint(x0, y0, r, angle) {
            let x1 = x0 + r * Math.cos(angle * Math.PI / 180)
            let y1 = y0 + r * Math.sin(angle * Math.PI / 180)
            return {
                x: x1,
                y: y1
            }
        },

        draw(option1,angle){
            angle = angle+3
           this.echinth.setOption(option1, true)
        //window.requestAnimationFrame(draw);
        }



   },
}




</script>
<style>

</style>
