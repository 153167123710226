<template>

      <!-- 全局容器 -->
        <v-scale-screen width="1920" height="1080">
  <!-- <div>
    <v-chart>....</v-chart>
    <v-chart>....</v-chart>
    <v-chart>....</v-chart>
    <v-chart>....</v-chart>
  </div> -->

    <!-- <v-chart> -->
          <div id="index" :style="widths" >
            <div class="Overallstyle">
              <!-- 左 -->
              <div class="box right">
                <indexRight></indexRight>
              </div>
              <!-- 中 -->
              <div class="box center">
                <indexCenter></indexCenter>
              </div>
              <div style="width:0.2%;height:100%;"></div>
              <!-- 右 -->
              <div class="box left">
                <indexLeft></indexLeft>
              </div>
            </div>

          </div>


</v-scale-screen>

</template>

<script>
// @ is an alias to /src
import indexRight from '@/components/index-right.vue'
import indexLeft from '@/components/index-left.vue'
import indexCenter from '@/components/index-center.vue'


export default {
  name: 'HomeView',
   components: {
    indexRight,
    indexLeft,
    indexCenter
  },


  props:[],
  data(){
    return {
        scwidth:1920,
        scheight:750,
        Cheight:100,
        time:20,
        flog:1,
        table:true,

        widths:{
          width:100+'%',
          height:1080+"px",


        },

        yy_img1:{
          // background:"url("+require("@/assets/img/bntactive.png")+") no-repeat",
          // width: 100+'%',
          // height:100+'%',
          // backgroundSize:'100%  100%',
          // display: 'flex',
          // justifyContent: 'cente',
          // alignItems: 'center',
        },
        yy_img2:{
          // background:"url("+require("@/assets/img/bntactive.png")+") no-repeat",
          // width: 100+'%',
          // height:100+'%',
          // backgroundSize:'100%  100%',
          // display: 'flex',
          // justifyContent: 'cente',
          // alignItems: 'center',
        },
         yy_img3:{
          // background:"url("+require("@/assets/img/bntactive.png")+") no-repeat",
          // width: 100+'%',
          // height:100+'%',
          // backgroundSize:'100%  100%',
          // display: 'flex',
          // justifyContent: 'cente',
          // alignItems: 'center',
        },
        yy_img4:{
          // background:"url("+require("@/assets/img/bntactive.png")+") no-repeat",
          // width: 100+'%',
          // height:100+'%',
          // backgroundSize:'100%  100%',
          // display: 'flex',
          // justifyContent: 'cente',
          // alignItems: 'center',
        },



    }
  },

  mounted(){




  },
  beforeUpdate (){

  },
 createcode(){

 },
  updated(){

  },

  created(){

},
  methods:{


    huoq(yu){

      this.$nextTick(()=>{
          //console.log('6786==>',this.$refs.uui);


      })


    },






  },

}
</script>

<style>
#index{
  margin: 0px auto;
  width: 100%;
  background: url("@/assets/img/1234.png")
}

.Overallstyle{
width:100%;
height: 100%;
/*  ; */
/* 弹性盒子布局 */
display: flex;
justify-content: space-around;
}

.box{
  height: 100%;
  /*  ; */
}

.left{
  width: 20%;
}

.center{
  width: 60%;
}

.right{
  width: 21%;
}





</style>
