<template>
  <div class="headsty">
    <el-row type="flex" justify="space-between">
      <el-col :span="7">
        <div class="box-right"></div>
      </el-col>
      <el-col :span="11"
        ><div class="textsty" @click="showheald">

            <div class="textyy">雅安智算中心</div>
            <div class="imgsty"></div>
        </div>
        <div class="BottomLine"></div>
      </el-col>
      <el-col :span="7"
        ><div class="box-left" id="seconds">
          <div  ref="timevalue" class="box-left-le1">
            {{datetime.time}}
          </div>
          <div class="box-left-le2">
            <div ref="dayvalue" >{{datetime.day}}</div>
            <div  ref="datevalue" style="margin-top:5px">{{datetime.data}}</div>
          </div>

          <!-- {{ getCurrentTime() }} -->
        </div></el-col
      >
    </el-row>

    <div v-if="Briefpopshow" class="Briefpop-up">
      <div class="Briefpop-up-img">
        <div class="Briefpop-up-text">
          <div class="Off" @click="offOn"></div>
          <div class="header">简介</div>
          <div class="mainBody"> 居中的盒子做简介弹出框居中的盒子做简介弹出框居中的盒子做简介弹出框居中的盒子做简介弹出框居中的盒子做简介弹出框居中的盒子做简介弹出框居中的盒子做简介弹出框居中的盒子做简介弹出框</div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
export default {
  name: "APP",
  data() {
    return {
      datetime:{},
      Briefpopshow:false
      // 当前时间
    };
  },
  methods: {
    offOn(){
      this.Briefpopshow=false

    },

    showheald(){
      return
      this.Briefpopshow=true
    },


    gettime() {
      // 设置每秒更新时间
      setInterval(()=>{
        this.getCurrentTime()
        // console.log("this.$refs.timevalue==>",this.$refs)
        this.$refs.timevalue.innerHTML = this.datetime.time;
        this.$refs.dayvalue.innerHTML = this.datetime.day;
        this.$refs.datevalue.innerHTML = this.datetime.data;
      }, 1000);
    },

    getCurrentTime() {
      const now = new Date();
      const daysolde =[
        {
          key:"Sunday",
          name:"星期日"
        },
        {
          key:"Monday",
          name:"星期一"
        },
        {
          key:"Tuesday",
          name:"星期二"
        },
        {
          key:"Wednesday",
          name:"星期三"
        },
        {
          key:"Thursday",
          name:"星期四"
        },
        {
          key:"Friday",
          name:"星期五"
        },
        {
            key:"Saturday",
            name:"星期六"
        }
      ]

      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours()<10?"0"+now.getHours():now.getHours();
      const minutes = now.getMinutes()<10?"0"+now.getMinutes():now.getMinutes();
      const seconds = now.getSeconds()<10?"0"+now.getSeconds():now.getSeconds();
      // console.log("时间===》>",`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,"星期==>", now.getDay())
      this.datetime.data=`${year}年${month}月${day}日`
      this.datetime.time=`${hours}:${minutes}`
      this.datetime.day=daysolde[now.getDay()].name
      return this.datetime
    },
  },
  created() {
    // 获取当前时间
    this.gettime()
  },
  mounted() {},
};
</script>
<style scoped>
.Off{
  float: right;
  width:1.2rem;
  height:1.2rem;
  /* border:1px solid red */
}
.header{
  margin: 0 auto;
  /* border: 1px solid rgb(224, 183, 233); */
  width: 6.9rem;
  height: 1.5rem;
  transform: translate(9%, 0%);

  /* 字体居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 字体居中 */
  font-size: 0.6rem;
  font-weight: 600;
}
.mainBody{
  /* border:1px solid red; */
  width: 21rem;
  height: 17rem;
  margin: 0 auto;
  /* 文本设置首行缩进 */
  text-indent: 1rem;
  /* 文字间隔 */
  letter-spacing: 0.04rem;
  /* 文字大小 */
  font-size: 0.3rem;
  /* 行间距 */
  line-height: 0.4rem;

}

.Briefpop-up{
  /* border:1px solid red; */
  /* 设置屏幕居中 */
  position: absolute;
  /* top: 50%;
  left: 50%; */
  transform: translate(-5%, -9%);
  width: 23rem;
  height: 19rem;
    background-color: rgba(84, 154, 252, 0.454);
    z-index: 1000;
    /* 设置圆角 */
    border-radius: 0.5rem;

  /* margin:auto; */
  -webkit-animation:Briefpop .3s  ;
}

@-webkit-keyframes Briefpop
{
  0%{
    transform:scale(0);
  }
  100%{
    transform: scale(1) translate(-1.13rem, -1.71rem) ;
  }
}


.Briefpop-up-img{
  width: 100%;
  height: 100%;
   background: url("@/assets/img/eject1.png") no-repeat;
   background-size: 100% 100%;
}

.BottomLine {
  width: 100%;
  height: 13px;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    #c8c8c8 53%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.8;
}
.headsty {
  width: 100%;
  height: 100%;
  background: url('@/assets/img/jiaoT1.png') no-repeat;
  background-size:100%  95% ;
 justify-content: center;
  align-items: center;

}
.textyy {

  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: bold;
  font-size: 120px;
  color: #ffffff;
  line-height: 330px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.imgsty{
/*  ; */
width: 30%;
height: 36%;
margin-left: 60px;
background: url('@/assets/img/u195.png') no-repeat;
  background-size:100%  100% ;
 justify-content: center;
  align-items: center;
}



.textsty {
  /* 字体居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* 字体样式 */
  font-weight: 600;
  color: #fff;

  /* 字间距调整 */
  letter-spacing: 2px;
}
/* 关闭点击事件 */
.textsty1:hover{
  /* 变手型 */
  cursor: pointer;
}

.box-right {
  width: 100%;
  height: 100%;
  /*   ; */
  display: flex;
  font-size: 50px;
  font-weight: 600;
  color: #fff;
  line-height: 330px;
  /* 字间距调整 */
  letter-spacing: 5px;
  background: linear-gradient( 90deg, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0) 100%);
  border-radius: 0px 0px 0px 0px;
}
.box-left {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /*   ; */
  background: linear-gradient( 270deg, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0) 100%);
  border-radius: 0px 0px 0px 0px;
}
.box-left-le1 {
  font-size: 80px;

  color: #fff;
  line-height: 330px;
  /*   ; */
  /* 字间距调整 */
  letter-spacing: 5px;
  width: 24%;
/*font-family: Microsoft YaHei, Microsoft YaHei;*/
font-weight: 600;
color: #FFFFFF;
text-align: left;
font-style: normal;
text-transform: none;

}

.box-left-le2 {
  font-size: 60px;
  /* font-weight: 600; */

  /* line-height: 330px; */
  /*   ; */
  /* 字间距调整 */
  letter-spacing: 5px;
  width: 47%;
  /*
height: 26px; */
/*font-family: Microsoft YaHei, Microsoft YaHei;*/

/* font-size: 20px; */
color: #FFFFFF;
/* line-height: 0px; */
text-align: left;
font-style: normal;
text-transform: none;
font-weight: 400;

}



</style>
