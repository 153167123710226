<template>
  <div id="app">
    <transition  name="el-fade-in">
      <transition  name="el-fade-in">
        <dv-loading v-if="ffu==1">Loading...</dv-loading>
      </transition>
    </transition>
     <transition  name="el-fade-in">
          <transition  name="el-fade-in-linear">
            <div v-if="ffu==2" class="box-zi">
              <nav>
              <router-view/>
              </nav>
            </div>
          </transition>
      </transition>
  </div>
</template>

<script>
export default{
data() {
    return {
      ffu:1
    }
  },

  mounted(){
      setTimeout(() => {
          this.ffu=2
      }, 2000);
  }

}

</script>

<style>
@import "assets/font/font.css";
#app {
width: 100vw;
height: 100vh;
background-color: black;
/*background-color: black*/
/* background-image: linear-gradient(to right,
 rgb(4, 12, 48), rgb(9, 29, 116),
  rgb(7, 30, 136),rgb(13, 48, 204),
   rgb(7, 30, 136),rgb(9,29,116),
    rgb(4, 12, 48));*/

    color: #fff;
    font-size: 16px
}

.el-table td{
  color: rgb(253, 254, 255) !important;
  font-size: 45px
}

.el-textarea.is-disabled .el-textarea__inner{
  background-color: #f5f7fa00 !important;
  border-color: #e4e7ed00  !important;

}

.el-textarea.is-disabled .el-textarea__inner:hover{
  cursor:auto !important
}

/*表单去掉背景颜色*/
.el-table td.el-table__cell div{
        line-height: 0.5rem
}
.el-table th.el-table__cell > .cell{

    line-height: 0.5rem
}

.el-table, .el-table__expanded-cell {
        background-color: transparent!important;
    }

    .el-table th, .el-table tr {
      font-size: 45px;
       border: 0!important;/**/
        background-color: transparent!important;

    }




/* 去掉边框线*/
     .el-table--border tr,td{
      /**/ border: none!important;

    }
    .el-table::before{
      height:0;
    }

    /*修改滚动条样式*/

    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    ::-webkit-scrollbar {
    width: 0px; /*滚动条宽度*/
    height: 0px; /*滚动条高度*/
    background-color: rgb(5, 36, 119);
    }

    /*定义滑块 内阴影+圆角*/
    ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(216, 39, 39, 0.3);
    background-color:  /*滚动条的背景颜色*/rgb(55, 105, 233);
    }

    /*修改高亮*/
    .el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color:rgba(9, 64, 247, 0.473) !important;
    color: rgb(240, 248, 255)!important;
    }

    /*修改头部字体*/
.el-table thead{
          color:rgba(253, 255, 255, 0.7)!important;
          background-color:rgba(76, 94, 158, 0.7) !important;

    }




</style>
