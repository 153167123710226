<template>
    <div>
          <div ref="echaters" style="width:7rem;height:205px; "></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    data(){
        return{
            echinth:null,
        }
    },
   created(){

   },
   mounted(){
        this.echartsinit();
        this.getdata();

   },
   computed:{
   },
   methods:{
    //    创建echarts对象
    echartsinit(){
        this.echinth = echarts.init((this.$refs.echaters))
    },
    // 请求数据
    getdata(){

        this.upecharts()
    },

    //初始化对象
    upecharts(){
       let dataarr=[
        {
          data:569,
          name:'智能问答',
          color:'#0099FF'
        },
        {
          data:312,
          name:'AI画图',
          color:'#0099FF'
        },
        {
          data:227,
          name:'内容创作',
          color:'#0099FF'
        },
        {
          data:141,
          name:'AI翻译',
          color:'#0099FF'
        },
        {
          data:87,
          name:'代码辅助',
          color:'#0099FF'
        },
        {
          data:16,
          name:'商业分析',
          color:'#0099FF'
        }
       ]
        // 排序
      let setdata=dataarr.sort((a, b) =>a.data-b.data)
      let datas=setdata.map(x=>x.data)
      let names=setdata.map(x=>x.name)
      console.log("setdatasetdata==>",setdata,datas,names)


 let option = {
    grid: {
        top: 0,
        left: 80,
        right: 70,
        bottom: 0,
        containLabel: false,
    },
    xAxis: {
        type: 'value',
        show:false,
        axisLine: {
            show: true,
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.2)',
            },
        },
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 14,
            color: 'rgba(211, 232, 255, 1)',
            formatter: function (param) {
                return param + '%';
            },
        },
        min: 0,
        // max: 130,
        splitLine: {
            show: false,
        },
    },
    yAxis: {
        show:true,
        type: 'category',
        axisLine: {
            show: false,
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.2)',
            },
        },
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 14,
            color: 'rgba(211, 232, 255, 1)',
        },
        data:names,
    },
    series: [
        {
            type: 'bar',
            barGap: '-65%',
            label: {

                normal: {
                    distance:20,
                    show: true,
                    position: 'right',
                    color: '#fff',
                    fontSize: 14,
                    formatter: function (param) {
                        return datas[param.dataIndex];
                    },
                },
            },
            barWidth: 12,
            itemStyle: {
                normal: {
                    color: 'RGBA(3, 43, 115, 0)',
                },
            },
            z: 1,
            data: [1000, 1000, 1000,1000,1000,1000],
        },
        {
            type: 'bar',
            barGap: '-100%',
            barWidth: 12,
            itemStyle: {
                normal: {
                    show: true,
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        {
                            offset: 0,
                            color: 'rgba(90, 130, 251, 1)',
                        },
                        {
                            offset: 1,
                            color: 'rgba(76, 179, 241, 1)',
                        },
                    ]),
                },
            },
            // max: 1,
            labelLine: {
                show: true,
            },
            // z: 2,
            data: datas,
        },
    ],
};

        this.echinth.setOption(option, true)


        },

         getCirlPoint(x0, y0, r, angle) {
            let x1 = x0 + r * Math.cos(angle * Math.PI / 180)
            let y1 = y0 + r * Math.sin(angle * Math.PI / 180)
            return {
                x: x1,
                y: y1
            }
        },

        draw(option1,angle){
            angle = angle+3
           this.echinth.setOption(option1, true)
        //window.requestAnimationFrame(draw);
        }



   },
}




</script>
<style>

</style>
