<template>
  <div>
     <!-- 主要内容 -->
                    <div class="box-Z" ref="uuheight">
                        <!-- 右 -->
                        <div class="Rest">
                          <div class="boxy-l">
                            <div class="img-box">
                              <div class="headsty">服务概览</div>
                              <div class="boxy-1-bottom">
                                <div class="boxy-1-text1">
                                  <div class="textkey">累计服务企业数量</div>
                                  <div class="textvalue">7</div>
                                  <br/>
                                  <div class="textkey" style="margin-top: 50px;">大模型数量</div>
                                  <div class="textvalue">12</div>
                                </div>
                                <div class="boxy-1-text2">
                                  <div class="textkey" >累计算力调用量</div>
                                  <div class="textvalue">2348</div>
                                  <br/>
                                  <div class="textkey" style="margin-top: 50px;">人工智能应用量</div>
                                  <div class="textvalue">38</div>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="boxy-2">
                            <div class="img-box">
                              <div class="headsty">企业分布</div>
                              <div class="bottom-box">
                                <div style="margin-top:10px;width: 100%;height:100%;display: flex;justify-content: center">
                                  <RightPieChart></RightPieChart>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="boxy-2">
                            <div class="img-box">
                              <div class="headsty">大模型用量分布</div>
                              <div class="bottom-box">
                                <div style="margin-top:10px;width: 100%;height:100%;display: flex;justify-content: center">
                                  <RightPieChart1></RightPieChart1>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="boxy-2">
                            <div class="img-box">
                              <div class="headsty">应用分布</div>
                              <div class="bottom-box2">
                                <div style="width: 100%;height:100%;display: flex;justify-content: center">
                                  <RightBarChart></RightBarChart>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="false" class="boxy-2">
                            <div class="img-box">
                              <div class="headsty">应用数量统计</div>
                              <div class="bottom-box">
                                <div style="margin-top:10px;width: 100%;height:100%;display: flex;justify-content: center">
                                  <RightBarChart1></RightBarChart1>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="boxy-4">
                            <div class="img-box">
                              4
                            </div>
                          </div> -->
                          <!-- <div class="boxy-5">
                            <div class="img-box">
                              5
                            </div>
                          </div> -->


                        </div>

                        </div>


  </div>
</template>

<script>
import RightPieChart from "@/components/rightmod/RightPieChart.vue";
import RightPieChart1 from "@/components/rightmod/RightPieChart1.vue";
import RightBarChart from "@/components/rightmod/RightBarChart.vue";
import RightBarChart1 from "@/components/rightmod/RightBarChart1.vue";
export default {
  name: 'HelloWorld3',
  components: {
    RightPieChart,
    RightPieChart1,
    RightBarChart,
    RightBarChart1
  },
  data(){
    return {
        Cheight:0,
        time:20
    }
  },
  mounted(){

      this.use()

    //  window.onresize =  ()=> {
    //   this.Cheight =document.documentElement.clientHeight-120
    //    var hg = this.$refs.uuheight
    //    hg.style.height=this.Cheight+"px";

    // };
  },
  methods:{
    use(){
        var w=1920
        this.Cheight=1080-20
        var hg = this.$refs.uuheight
        hg.style.height=this.Cheight+"px";
        // //console.log(w,this.Cheight,hg)

      },
      funstart(){
        // 获得宽
             //console.log("宽度", document.documentElement.clientWidth);
        //  获得高
            //console.log("高度", document.documentElement.clientHeight);
      }
  }

}
</script>

<style type="less" scoped>

/deep/ .dv-border-box-7 .border-box-content{
  background-color: rgb(164 164 164 / 19%);
}
.headsty{
  width: 70%;
  height: 15%;
  margin-left: 70px;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 400;
  font-size: 55px;
  color: #FFFFFF;
  line-height: 130px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  letter-spacing: 3px;
}

.boxy-1-bottom{
  width: 95%;
  height: 70%;
  margin-top: 0.5rem;
  display:flex;
  justify-content:center;
}
.bottom-box{
  width: 100%;
  height: 80%;
  margin-top: 0.5rem;
  display:flex;
  justify-content:center;
}

.textkey{
margin-top:30px;
height: 12px;
/*font-family: Microsoft YaHei, Microsoft YaHei;*/
font-weight: 500;
font-size: 60px;
color: #FFFFFF;
text-align: right;
font-style: normal;
text-transform: none;


}

.textvalue{
height: 20px;
line-height: 240px;
/*font-family: Digital-7, Digital-7;*/
font-family: electronicFont;
font-weight: normal;
font-size: 120px;
color: #FFFFFF;
text-align: right;

}

.boxy-1-text1{

  height: 100%;

}
.boxy-1-text2{
  width:45%;
  height: 100%;

}

.img-box{
    /* 设置背景图片 */
    background: url('@/assets/img/beijing.png') no-repeat;
    width:100%;
    height: 100%;
    background-size:100%  100% ;
}

.boxy-l{
  width:98%;
  height: 30%;
  margin-left: 6px;
  margin-top:  3.5%;


  /* background: rgba(112, 144, 206, 0.1);  ; */
}


.boxy-2{
  width:98%;
  height: 35%;
  margin-left: 6px;
  margin-top:  3.5%;


}
.boxy-3{
  width:98%;
  height: 50%;
  margin-left: 6px;
  margin-top:  3.5%;


}
.boxy-4{
  width:98%;
  height: 45%;
  margin-left: 6px;
  margin-top:  3.5%;


}

.boxy-5{
  width:98%;
  height: 45%;
  margin-left: 6px;
  margin-top:  3.5%;


}

.box-Z{
  display: flex;
  justify-content:space-around;
  height:100%;

}
.Rest{
  display: flex;
  width: 100%;
  /*  ;*/
  flex-direction:column


}



</style>
