<template>

  <div v-if="false" style="width: 100%; height: 100%">
    <div class="top">
      <div class="top-boxsty-top">
        <CircularChart ref="CircularChart1"></CircularChart>
      </div>
      <div class="top-boxsty-bottom">
        <textsty ref="text1"></textsty>
      </div>
    </div>
    <div class="center">
      <div class="center-boxsty-top">
        <CircularChart ref="CircularChart2"></CircularChart>
      </div>
      <div class="center-boxsty-bottom">
        <textsty ref="text2"></textsty>
      </div>
    </div>
    <div class="right">
      <div class="right-boxsty-top">
        <CircularChart ref="CircularChart3"></CircularChart>
      </div>
      <div class="right-boxsty-bottom">
        <textsty ref="text3"></textsty>
      </div>
    </div>
  </div>
</template>
<script>
import textsty from "@/components/centermod/textsty.vue";
import CircularChart from "@/components/centermod/CircularChart.vue";
export default {
  components: {
    textsty,
    CircularChart,
  },
  data() {
    return {
      CircularChartData: [
        {
          ref: "CircularChart1",
          textref:"text1",
          valuearr: [
            {
              name: "蓝色",
              value: 25,
              rate: 25,
            },
            {
              name: "灰色",
              value: 100-25,
              rate: 100-25,
            },
          ],
          textdata:"32 | 128"
        },
        {
          ref: "CircularChart2",
          textref:"text2",
          valuearr: [
            {
              name: "蓝色",
              value: 50,
              rate: 50,
            },
            {
              name: "灰色",
              value: 100-50,
              rate: 100-50,
            },
          ],
          textdata:"12 | 35"
        },
        {
          ref: "CircularChart3",
          textref:"text3",
          valuearr: [
            {
              name: "蓝色",
              value: 50,
              rate: 50,
            },
            {
              name: "灰色",
              value: 100-50,
              rate: 100-50,
            },
          ],
          textdata:"6 | 12"
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getinif();
  },
  computed: {},
  methods: {
    getinif() {
      for (let key in this.CircularChartData) {
        let refs = this.CircularChartData[key].ref;
        let textref = this.CircularChartData[key].textref;

        console.log(
          "this.tuarr[key].refthis.tuarr[key].ref==>",
          refs,
          textref,
          this.$refs[refs],

        );
        // 等待组件加载完成
        // this.$nextTick(() => {
        //   this.$refs[refs].echartsinit(this.CircularChartData[key]);
        //   this.$refs[textref].getdata(this.CircularChartData[key]);
        // });
      }
    },
  },
};
</script>
<style scoped>
.top {
  width: 100%;
  height: 30%;
  /* */
}
.center {
  width: 100%;
  height: 30%;
  /* */
}

.center-boxsty-top {
  position: relative;
  top: 185px;
  left: -60px;
  width: 83%;
  height: 50%;
}

.right {
  width: 100%;
  height: 30%;
  /* */
}
.right-boxsty-top {
  position: relative;
  left: -290px;
  top: 460px;
  width: 83%;
  height: 50%;
}
.center-boxsty-bottom {
  position: relative;
  left: -90px;
  top: 235px;
  width: 90%;
  height: 50%;
}

.right-boxsty-bottom {
  position: relative;
  left: -480px;
  top: 530px;
  width: 90%;
  height: 50%;
}

.top-boxsty-bottom {
  position: relative;
  left: -200px;
  top: 50px;
  width: 90%;
  height: 50%;
  /* */
}
.top-boxsty-top {
  position: relative;
  left: -390px;
  top: 20px;
  width: 83%;
  height: 50%;
  /* */
}
</style>
