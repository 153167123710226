<!-- 弹框内容 -->
<template>
  <div v-if="true">
    <div class="header">{{headertext}}</div>
    <div class="mainBody">
      <div>
        <!-- 看板头 -->
        <div class="headBoard" >
          <div class="lookBoard" v-for="(item,index) in lookBoardarr" :key="index">
            <div class="keyname">{{item.name}}</div><div class="vlauename">{{item.value}}<span style="font-size:0.2rem">个</span></div>
          </div>
          <!-- <div class="lookBoard">
            <div class="keyname">POD容器组</div><div class="vlauename">90<span style="font-size:0.2rem">个</span></div>
          </div>
          <div class="lookBoard">
            <div class="keyname">POD容器组</div><div class="vlauename">90<span style="font-size:0.2rem">个</span></div>
          </div>
          <div class="lookBoard">
            <div class="keyname">POD容器组</div><div class="vlauename">90<span style="font-size:0.2rem">个</span></div>
          </div>
          <div class="lookBoard">
            <div class="keyname">POD容器组</div><div class="vlauename">90<span style="font-size:0.2rem">个</span></div>
          </div> -->
        </div>

        <!-- 表数据 -->
        <div class="tablesty-box">
            <tibleBiao></tibleBiao>


        </div>
      </div>
    </div>
  </div>
</template>
<script>

import tibleBiao from "@/components/centermod/tibleBiao.vue";
export default {
  components:{
    tibleBiao
  },
  props: {
    datas:{
      type:Object,
      default:()=>{
        return {}
      }
      }
  },
  name: "APP",
  data() {
    return {
      lookBoardarr:[],
      headertext:""
    };
  },
  methods: {},
  created() {},
  mounted() {
    this.lookBoardarr = this.datas.lokkeBoardarr
   this.headertext=this.datas.headertext
    console.log("this.lookBoardarr=>>",this.datas,this.lookBoardarr)
  },
};
</script>
<style scoped>
.keyname{
  font-weight:800;
  color: #fff;
}
.vlauename{
  font-size: 0.4rem;
  color: rgb(109 190 253);


}

.tablesty-box {
  display: block;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  width: 21rem;
  height: 8rem;
}


.headBoard {
  display: flex;
  justify-content: space-around;
  /* border: 1px solid red; */
  width: 21rem;
  height: 3rem;
}

.lookBoard {
  /* border: 1px solid red; */
  width: 3.5rem;
  height: 1.9rem;
  margin-top: 0.6rem;
  background: url("@/assets/img/k_1.png") no-repeat;
  background-size: 100% 100%;
  /* 文字居中 */
  display: flex;
  justify-content:space-around;
  align-items: center;
  /* 文字居中 */
  font-size: 0.3rem;
}



.header {
  margin: 0 auto;
  /* border: 1px solid rgb(224, 183, 233); */
  width: 12rem;
  height: 1.5rem;
  transform: translate(5.5%, 0%);
  /* 字体居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 字体居中 */
  font-size: 0.5rem;
  background: url("@/assets/img/bt123.png") no-repeat;
  background-size: 100% 100%;
}

.mainBody {
  /* border: 1px solid red; */
  width: 21rem;
  height: 12rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
</style>
