<template>
    <div>
          <div ref="echaters" style="width:7rem;height:165px; "></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    data(){
        return{
            echinth:null,
        }
    },
   created(){

   },
   mounted(){
        this.echartsinit();
        this.getdata();

   },
   computed:{
   },
   methods:{
    //    创建echarts对象
    echartsinit(){
        this.echinth = echarts.init((this.$refs.echaters))
    },
    // 请求数据
    getdata(){

        this.upecharts()
    },

    //初始化对象
    upecharts(){

 let option = {

   title: {
      text: "单位：次",
      left: 'right',
      // top: 18,
      textStyle: {
         color: '#fff',
         fontWeight: 'normal',
         fontSize: 14
      }
   },
   //   backgroundColor:"#0F285C",
   grid: {
      containLabel: false,
      top: 30,
      right: 15,
      bottom: 30,
      left: 60
   },
   tooltip: {
      show:true,
      trigger: "axis",
      axisPointer: {
			type: 'shadow'
		},
      formatter: params => {
        //  //console.log(params)
         const {
            name,
            data,
         } = params[0];
         return `
            <span style="font-size: 14px;font-family: Source Han Sans CN-Medium;font-weight: 500;color: #FFFFFF;margin-bottom:12px;">${name}</span>
            <sapn style="font-size: 14px;font-family: Source Han Sans CN-Medium;font-weight: 500;color: #FFFFFF;margin-bottom:4px;">：${data} 次</span>
         `
      },
      extraCssText: 'opacity: 0.8;background-color:#050F1B;padding:16px;box-shadow: 1px 6px 15px 1px rgba(0,0,0,0.13);border-radius: 4px;filter: blur(undefinedpx);border:none;'
   },

   dataZoom: [
      {
         show: true,
         height: 5,
         bottom: '2px',
        //  top: '0px',
         right: '12%',
         showDetail: false,
         brushSelect: false,
         backgroundColor: '#314d70',
         showDataShadow: false,
         borderColor: 'transparent',
         fillerColor: '#3e9bee',
         start: 0,
         end: 35,
         handleSize: '90%',
         zoomLock: false,
         // 画一个圆形
         handleIcon:
            'path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5M36.9,35.8h-1.3z M27.8,35.8 h-1.3H27L27.8,35.8L27.8,35.8z',
         handleStyle: {
            // 两侧缩放手柄的样式配置。
            borderColor: '#cacaca',
            borderWidth: '5',
            shadowBlur: 1,
            background: '#ddd',
            shadowColor: '#ddd'
         }
      }
   ],
   xAxis: {
      // 类目轴
      type: "category",
      data: ['一月','二月', '三月', '四月', '五月', '六月', '七月', '八月','九月','十月', '十一月','十二月'],
      axisTick: {
         show: false //隐藏X轴刻度
      },
      axisLine: {
         lineStyle: {
            color: "#CCCCCC"
         }
      },
      axisLabel: {
         show: true,
         textStyle: {
            color: '#fff',
            fontSize: 14,
            // fontFamily: 'Source Han Sans CN-Regular',
         }
      },
   },
   yAxis: {
      type: 'value',
      name: "",
      max:12000,
      nameTextStyle: {
         color: '#fff',
         // fontFamily: 'Source Han Sans CN-Regular',
        //  align: "left",
        //  verticalAlign: "center",
      },
      axisLabel: {
         color: '#fff',
         textStyle: {
            fontSize: 12
         },
      },
      axisLine: {
         show: false,
         lineStyle: {
            color: 'rgba(223, 223, 223, 1)',
         }
      },
      axisTick: {
         show: false
      },
      splitLine: {
         lineStyle: {
            color: 'rgba(223, 223, 223, 1)',
            // type: "dashed",
         }
      }
   },
   series: [
      {
         type: "line",
         barWidth: "30%",
        //  showBackground: true,
         backgroundStyle: {
            color: "transparent"
         },
         itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
               { offset: 0, color: "#4B87FFFF" },
               { offset: 1, color: "#82BCFFFF" }
            ])
         },
         data: [1000, 2100,4120]
      }
   ]
}

        this.echinth.setOption(option, true)


        },

         getCirlPoint(x0, y0, r, angle) {
            let x1 = x0 + r * Math.cos(angle * Math.PI / 180)
            let y1 = y0 + r * Math.sin(angle * Math.PI / 180)
            return {
                x: x1,
                y: y1
            }
        },

        draw(option1,angle){
            angle = angle+3
           this.echinth.setOption(option1, true)
        //window.requestAnimationFrame(draw);
        }



   },
}




</script>
<style>

</style>
