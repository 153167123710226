<template>
  <div class="movingboxstyle">
    <!-- <div class="roundness-center-img-sty1"></div> -->
    <div v-if="true" class="roundness-center-img-sty">
      <!-- 上面的图 -->
      <div class="falotsty falotsty-tu1 box1 img1" ></div>
      <div class="falotsty falotsty-tu1 box2 img2" ></div>
      <div class="falotsty falotsty-tu1  box3 img3" ></div>
      <div class="falotsty falotsty-xian1 xian1" ></div>
      <div class="falotsty falotsty-xian1 xian2" ></div>
      <div class="falotsty falotsty-tu2 lutu1"></div>
      <div class="falotsty falotsty-text1 text1" ></div>
      <div class="falotsty falotsty-text1 text2" ></div>
      <div class="falotsty falotsty-text1 text3" ></div>
      <!-- 导线图 -->
      <div class="falotsty falotsty-xian5 xian5"></div>
      <!-- 下面的图 -->
      <div class="falotsty falotsty-tu1 box4 img4" ></div>
      <div class="falotsty falotsty-tu1 box5 img5" ></div>
      <div class="falotsty falotsty-tu1 box6 img6" ></div>
      <div class="falotsty falotsty-xian1 xian3" ></div>
      <div class="falotsty falotsty-xian1 xian4" ></div>
      <div class="falotsty falotsty-tu2 lutu2"></div>
      <div class="falotsty falotsty-text1 text4" ></div>
      <div class="falotsty falotsty-text1 text5" ></div>
      <div class="falotsty falotsty-text1 text6" ></div>
      <div ref="cursor" class="falotsty falotsty-guang guandian" ></div>

      <!-- <div class="falotsty falotsty-tu2">82</div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "APP",
  data() {
    return {
      cursortop:0
    };
  },

  watch: {

  },

  methods: {
    // setdomtop(top,left){
    //   console.log("设置移动==》",this.$refs.cursor)
    //   let stop=this.$refs.cursor.offsetTop;
    //   let sleft=this.$refs.cursor.offsetLeft;
    //   setInterval(() => {

    //   }, 1000);

    // }
  },
  created() {},
  mounted() {
    // this.setdomtop()
  },
};
</script>
<style  scoped>
.movingboxstyle{
  width: 100%;
  height: 100%;
  position: relative;
    left: -25px;
    transform: translate(23%, 0%) scale(1.5);
}
.roundness-center-img-sty{
  width: 90%;
  height: 90%;
  position: relative;
  /* offset-path: path("M 300 400 L 900 480"); */
  -webkit-animation:roundcenter 2.5s infinite alternate linear;
  /* background: url("@/assets/img/test123.gif") no-repeat;
  background-size: 100% 100%; */
}
@-webkit-keyframes roundcenter{
  0% {
      transform: translate(0%, 0%);
    }
    100% {
      transform: translate(0%, 1%);
    }
}


.falotsty{
  float: left
}

.falotsty-tu1{
  width: 17%;
  height: 17.5%;
  /*  ; */
}

.falotsty-xian1{
  width: 11%;
  height: 6.38%;
  /*  ; */
}

.falotsty-guang{
  width: 13%;
  height: 13%;
  /*  ; */
}


.falotsty-xian5{
  width: 12.1%;
  height: 11.2%;
  /*  ; */
}

.falotsty-tu2{
  width: 80%;
  height: 48.45%;
  /*  ; */
}

.falotsty-text1{
  width: 10%;
  height: 9.2%;
  /*  ; */
}

.img1{
    background: url("@/assets/img/img11.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: 43%;
    left: -7%;
}
.img2{
    background: url("@/assets/img/img22.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

 top: 32.5%;
 left: -6.5%;
}
.img3{
    background: url("@/assets/img/img33.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: 23%;
    left: -6.5%;
}
.img4{
    background: url("@/assets/img/img44.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -21%;
    left: 19.5%;
}
.img5{
    background: url("@/assets/img/img55.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -11%;
 left: -15.5%;
}
.img6{
    background: url("@/assets/img/img66.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

 top: -19%;
 left: 27.5%;
}

.lutu1{
  background: url("@/assets/img/lutu1.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: 4%;
    left: -19.5%;
}


.lutu2{
  background: url("@/assets/img/lutu2.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -57%;
    left: 13.5%;
}

.xian1{
  background: url("@/assets/img/xian1.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: 47.5%;
 left: -46%;
}
.xian2{
  background: url("@/assets/img/xian1.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: 37%;
 left: -39%
}
.xian3{
  background: url("@/assets/img/xian3.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -25%;
 left: 39%;

}

.xian4{
  background: url("@/assets/img/xian4.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -15%;
    left: 9%;

}
.xian5{
  background: url("@/assets/img/xian5.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -27%;
    left: 22%;
}

.text1{
    background: url("@/assets/img/text1.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

 top: -10%;
 left: 4%;

}

.text2{
  background: url("@/assets/img/text2.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

 top: -20%;
 left: 11%;
}

.text3{
  background: url("@/assets/img/text3.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

 top: -29%;
 left: 18%;
}
.text4{
  background: url("@/assets/img/text4.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

 top: -92%;
 left: 94%;
}
.text5{
  background: url("@/assets/img/text5.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -81%;
    left: 66%;
}
.text6{
  background: url("@/assets/img/text6.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

    top: -70%;
 left: 37%;
}

.guandian{
  background: url("@/assets/img/guandian.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -119%;
    left: -16%;
    /* offset-path: path("M 40 150 L 140 100 L 260 30 L 370 70 L 440 110 L 500 180 L 370 260 L 260 320"); */
    offset-path: path("M 40 150 L 150 90  L 250 30 L 350 70 L 410 110 L 450 160 L 340 220 L 240 280");
    -webkit-animation:myfirst1 10s infinite ;
}
@-webkit-keyframes myfirst1{
  0% {
        offset-distance: 0%;
    }

    15% {
        offset-distance: 17%;
    }
    30% {
        offset-distance: 32%;
    }

    55% {
        offset-distance: 65%;
    }

    75% {
        offset-distance: 85%;
    }

    100% {
        offset-distance: 100%;
    }
}

.box1{
	margin:auto;
	animation:myMove1 10s infinite ;
}
@keyframes myMove1{
	0%{transform:scale(1)}
  15%{transform:scale(0.9)}
	30%{transform:scale(0.9)}
	55%{transform:scale(0.9)}
	75%{transform:scale(0.9)}
  100%{transform:scale(0.9)}
}

.box2{
	margin:auto;
	animation:myMove2 10s infinite ;
}
@keyframes myMove2{
	0%{transform:scale(0.9)}
  15%{transform:scale(1)}
	30%{transform:scale(0.9)}
	55%{transform:scale(0.9)}
	75%{transform:scale(0.9)}
  100%{transform:scale(0.9)}
}

.box3{
	margin:auto;
	animation:myMove3 10s infinite ;

}
@keyframes myMove3{
	0%{transform:scale(0.9)}
	15%{transform:scale(0.9)}
	30%{transform:scale(1)}
	55%{transform:scale(0.9)}
	75%{transform:scale(0.9)}
	100%{transform:scale(0.9)}
}

.box4{
	margin:auto;
	animation:myMove4 10s infinite ;
}
@keyframes myMove4{
	0%{transform:scale(0.9)}
	15%{transform:scale(0.9)}
	30%{transform:scale(0.9)}
	55%{transform:scale(1)}
	75%{transform:scale(0.9)}
	100%{transform:scale(0.9)}
}
.box5{
	margin:auto;
	animation:myMove5 10s infinite ;

}
@keyframes myMove5{
  0%{transform:scale(0.9)}
	15%{transform:scale(0.9)}
	30%{transform:scale(0.9)}
	55%{transform:scale(0.9)}
	75%{transform:scale(1)}
	100%{transform:scale(0.9)}
}

.box6{
	margin:auto;
	animation:myMove6 10s infinite ;

}
@keyframes myMove6{
  0%{transform:scale(0.9)}
	15%{transform:scale(0.9)}
	30%{transform:scale(0.9)}
	55%{transform:scale(0.9)}
	75%{transform:scale(0.9)}
	100%{transform:scale(1)}
}

</style>
