<template>
  <div ref="uuheight">
    <div v-if="Briefpopshow" class="Briefpop-up">
      <div class="Briefpop-up-img">
        <!-- 关闭按钮 -->
        <div class="Off" @click="offOn"></div>
        <!-- 主要内容 -->
        <PopUpWindow ref="PopUpWindow" :datas="datas"></PopUpWindow>
      </div>
    </div>

    <!-- 主要内容 -->
    <!--    标题 -->
    <div id="inhead">
      <indexheadmod></indexheadmod>
    </div>

    <!-- 主体 -->
    <div class="box-Z">
      <!-- 最左边的样式 -->
      <div class="Rest"></div>
      <div class="conston">
        <!-- 头顶的三个框 -->
        <div class="conston-head">
          <div v-for="(item,index) in headboxstyarr" :key="index" :class="item.class" >
            <div class="headboxsty" @click="showheald(item)">
              <div style="margin-top: 9%">{{item.name}}</div>
              <div style="margin-top: 7%">
                <span style="font-weight: 600; font-size: 25px">{{item.value1}}</span>
                <span style="font-weight: 400; font-size: 10px">
                  {{item.unit}}</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 最上边左右两边的字 -->
        <div class="text-box">
          <div class="text-box-left">
            <div class="textsty">Deep Learning</div>
          </div>
          <div class="text-box-right">
            <div class="textsty">Training Data</div>
          </div>
        </div>
        <!-- 中间的圆形图 -->
        <div class="roundness">
          <div class="roundness-left">
            <div style="width: 50%">
              <cetervueLeft></cetervueLeft>
            </div>
          </div>
          <div class="roundness-center">
            <!-- 最中间的三个图 -->
            <div class="roundness-center-left">
              <div class="left-img-sty-top">
                <p class="left-img-sty-top-text">运算推理</p>
              </div>
              <div class="left-img-sty-center">
                <p class="left-img-sty-center-text">自然语言处理</p>
              </div>
              <div class="left-img-sty-bottom">
                <p class="left-img-sty-bottom-text">评估优化</p>
              </div>
            </div>
            <div class="roundness-center-center">
              <MovingPicture></MovingPicture>
            </div>
            <!-- <div class="roundness-center-right">312312321</div> -->
          </div>
          <div class="roundness-right">
            <!-- 右边图 -->
            <div class="roundness-right-right">
              <cetervueRight></cetervueRight>
            </div>
            <!-- 左边文字 -->
            <div class="roundness-right-left">
              <div class="right-img-sty-top">
                <p class="right-img-sty-top-text">模型训练</p>
              </div>
              <div class="right-img-sty-center">
                <p class="right-img-sty-center-text">深度学习</p>
              </div>
              <div class="right-img-sty-bottom">
                <p class="right-img-sty-bottom-text">数据管理</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 最下面的一层文字 -->
        <div class="conston-bottom">
          <div class="conston-bottom-1">
            <div class="conston-bottom-1-text">Natural Language Processing</div>
          </div>
          <div class="conston-bottom-2">
            <div class="conston-bottom-2-text">雅安数字经济运营有限公司</div>
          </div>
          <div class="conston-bottom-3">
            <div class="conston-bottom-3-text">Data Management</div>
          </div>
        </div>
      </div>
      <!-- 最右边的样式 -->
      <div class="Lefst"></div>
    </div>
  </div>
</template>

<script>
import indexheadmod from "@/components/centermod/head.vue";
import cetervueLeft from "@/components/centervue/cetervueLeft.vue";
import cetervueRight from "@/components/centervue/cetervueRight.vue";
import MovingPicture from "@/components/centervue/MovingPicture.vue";
import PopUpWindow from "@/components/centervue/PopUpWindow.vue";

export default {
  name: "HelloWorld3",
  components: {
    indexheadmod,
    cetervueLeft,
    cetervueRight,
    MovingPicture,
    PopUpWindow
  },
  data() {
    return {
      Briefpopshow: false,
      Cheight: 0,
      time: 20,
      datas:{},
      headboxstyarr:[
        {
          id:1,
          name:'总计算能力',
          value1:'14.4',
          unit:'亿亿次/秒',
          class:"conston-head-left"

        },
        {
          id:2,
          name:'训练算力',
          value1:'128',
          unit:'PFlops',
          class:"conston-head-center",
          headertext:"训练集群详情",
          lokkeBoardarr:[
            {
              name:"POD容器组",
              value:"90"
            },
            {
              name:"POD运行中",
              value:"55"
            },
            {
              name:"POD失败",
              value:"11"
            },
            {
              name:"POD错误",
              value:"2"
            },
            {
              name:"POD等待",
              value:"22"
            }
        ]
        },
        {
          id:3,
          name:'推理算力',
          value1:'16',
          unit:'PFlops',
          class:"conston-head-right",
          headertext:"推理集群详情",
          lokkeBoardarr:[
          {
              name:"POD容器组",
              value:"70"
            },
            {
              name:"POD运行中",
              value:"61"
            },
            {
              name:"POD失败",
              value:"5"
            },
            {
              name:"POD错误",
              value:"3"
            },
            {
              name:"POD等待",
              value:"12"
            }
        ]
        }
      ]
    };
  },
  mounted() {
    this.use();

    //  window.onresize =  ()=> {
    //   this.Cheight =document.documentElement.clientHeight-120
    //    var hg = this.$refs.uuheight
    //    hg.style.height=this.Cheight+"px";

    // };
  },
  methods: {
    offOn() {
      this.Briefpopshow = false;
    },

    showheald(data) {
      return
      if(data.id==1){
        return
      }
      this.datas=data
      this.Briefpopshow = true;
    },

    use() {
      var w = 1920;
      this.Cheight = 1080 - 20;
      var hg = this.$refs.uuheight;
      hg.style.height = this.Cheight + "px";
      //console.log(w, this.Cheight, hg);
    },
    funstart() {
      // 获得宽
      //console.log("宽度", document.documentElement.clientWidth);
      //  获得高
      //console.log("高度", document.documentElement.clientHeight);
    },
  },
};
</script>

<style scoped>
.Off {
  float: right;
  width: 1.2rem;
  height: 1.2rem;
  /* border:1px solid red */
}

.Off:hover {
  /* 变手型 */
  cursor: pointer;
}
.header {
  margin: 0 auto;
  /* border: 1px solid rgb(224, 183, 233); */
  width: 12rem;
  height: 1.5rem;
  transform: translate(5.5%, 0%);

  /* 字体居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 字体居中 */
  font-size: 0.5rem;
  font-weight: 600;
  background: url("@/assets/img/bt123.png") no-repeat;
  background-size: 100% 100%;
}
.mainBody {
  /* border:1px solid red; */
  width: 21rem;
  height: 17.5rem;
  margin: 0 auto;
  /* 文本设置首行缩进 */
  text-indent: 1rem;
  /* 文字间隔 */
  letter-spacing: 0.04rem;
  /* 文字大小 */
  font-size: 0.3rem;
  /* 行间距 */
  line-height: 0.4rem;
}

.Briefpop-up-img {
  width: 100%;
  height: 100%;
  background: url("@/assets/img/eject1.png") no-repeat;
  background-size: 100% 100%;
}
/* 关闭手形状 */
.headboxsty1:hover {
  /* 变手型 */
  cursor: pointer;
}

.Briefpop-up {
  /* border:1px solid red; */
  /* 设置屏幕居中 */
  position: absolute;
  /* top: 50%;
  left: 50%; */
  transform: translate(0%, 20%);
  width: 21rem;
  height: 14rem;
  background-color: rgba(84, 154, 252, 0.454);
  z-index: 1000;
  /* 设置圆角 */
  border-radius: 0.5rem;

  /* margin:auto; */
  -webkit-animation: Briefpop 0.3s;
}

@-webkit-keyframes Briefpop {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1) translate(0rem,2.8rem);
  }
}

.boxs {
  display: flex;
  justify-content: center;
  align-content: center;
}

.conston-bottom-1 {
  float: left;
  /*  ; */
  width: 15%;

  /*
  height: 100%; */
}

.conston-bottom-1-text {
  /*  ; */
  width: 60%;
  text-align: center;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 800;
  font-size: 45px;
  color: #ffffff;
  line-height: 90px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.conston-bottom-2 {
  float: left;
  /*  */
  text-align: center;

  width: 69%;
  height: 100%;
  /*
   */
}

.conston-bottom-2-text {
  line-height: 18.5;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  color: #ffffff;
  font-weight: 800;
  font-size: 45px;
}

.conston-bottom-3 {
  float: right;
  /*  ; */
  width: 16%;
  position: relative;
  top: 21%;

  /*
  height: 100%; */
}

.conston-bottom-3-text {
  /*
 ;
  float: left;

  */
  /*  ; */
  margin-left: 240px;
  width: 50%;
  text-align: center;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 800;
  font-size: 45px;
  color: #ffffff;
  line-height: 90px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.roundness {
  float: left;

  width: 100%;
  height: 74%;
}

.roundness-left {
  /*  ; */
  /* top: 20px; */
  /* position: relative; */
  float: left;
  width: 14%;
  height: 100%;

  /* background: url("@/assets/img/quanzuo111.png");
  background-size: 111% 96%;
  justify-content: center; */
  /* align-items: center; */
}

.roundness-center {
  left: -420.5px;
  position: relative;
  /*
  top: -2462px;
  */
  float: left;
  width: 63.5%;
  height: 100%;
}

.roundness-center-left {
  float: left;
  /*border:1px solid rgb(224, 183, 233);*/
  height: 100%;
  width: 17.5%;
  /*background: url("@/assets/img/xiantu.png") no-repeat;*/
  /* background: url("@/assets/img/148.png") no-repeat;
  background-size: 130% 99%; */
}

.left-img-sty-top {
  float: left;
  /*  ; */
  width: 366px;
  height: 10%;
}

.left-img-sty-top-text {
  float: left;
  position: relative;
  top: 150px;
  left: 300px;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 800;
  font-size: 60px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  writing-mode: vertical-lr;
  transform: rotate(35deg);
}

.left-img-sty-center-text {
  /*  ; */
  width: 420px;
  float: left;
  position: relative;

  top: 800px;
  left: 0px;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 800;
  font-size: 60px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  writing-mode: vertical-lr;
  /* transform: rotate(-90deg); */
}

.left-img-sty-bottom-text {
  /*  ; */
  width: 320px;
  float: left;
  position: relative;
  top: -160px;
  left: 360px;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 800;
  font-size: 60px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  transform: rotate(-40deg);
  writing-mode: vertical-lr;
}

.left-img-sty-center {
  background: url("@/assets/img/148.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 80%;
  float: left;
  position: relative;
  top: -10px;
  left: -35px;
  /*  ; */
  transform: rotate(359deg);
}

.left-img-sty-bottom {
  width: 100%;
  height: 10%;
  float: left;
  /*  ; */
}

.roundness-center-center {
  /* float: left; */
  /* border:1px solid rgb(224, 183, 233); */
  height: 100%;
  width: 100%;
}

/* .roundness-center-img-sty{
  margin-left: -420px;
  display: inline;
  position:relative;
   ;
	-webkit-animation:myfirst 10s;
} */

/* 动画 */
/* @-webkit-keyframes myfirst
{
    0%   {background: red; left:0px; top:0px;}
    25%  {background: yellow; left:200px; top:0px;}
    50%  {background: blue; left:200px; top:200px;}
    75%  {background: green; left:0px; top:200px;}
    100% {background: red; left:0px; top:0px;}
} */

.roundness-center-right {
  float: right;

  /* background: url("@/assets/img/xiantu.png") no-repeat;
  background-size: 97% 103%; */
  height: 100%;
  width: 21%;
}

.roundness-right {
  /*  ; */
  left: 200px;
  top: 0px;
  position: relative;
  float: right;
  width: 20%;
  height: 100%;

  /*
  background: url("@/assets/img/quanyou123.png") no-repeat;
  background-size: 100% 99%;
  */
}
.roundness-right-right {
  float: right;
  width: 50%;
  height: 100%;
}
.roundness-right-left {
  float: left;
  width: 50%;
  height: 100%;
}

.right-img-sty-top {
  float: left;
  height: 10%;
  width: 100%;
  /*  ; */
}

.right-img-sty-center {
  left: 108px;
  top: -20px;
  position: relative;
  background: url("@/assets/img/xiantu.png") no-repeat;
  background-size: 100% 100%;
  float: left;
  height: 83%;
  width: 100%;
  /*  ; */
  transform: rotate(359deg);
}

.right-img-sty-bottom {
  float: left;
  height: 10%;
  width: 100%;
  /*  ; */
}

.right-img-sty-top-text {
  /*  ; */
  width: 280px;
  float: left;
  position: relative;
  top: 60px;
  left: 50px;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 800;
  font-size: 60px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  transform: rotate(-35deg);
  writing-mode: vertical-lr;
}

.right-img-sty-center-text {
  /*  ; */
  width: 280px;
  float: left;
  position: relative;
  top: 830px;
  left: 300px;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 800;
  font-size: 60px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  /* transform: rotate(93deg); */
  writing-mode: vertical-lr;
}

.right-img-sty-bottom-text {
  /*  ; */
  width: 280px;
  float: left;
  position: relative;
  top: -80px;
  left: 30px;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 800;
  font-size: 60px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  transform: rotate(40deg);
  writing-mode: vertical-lr;
}

.const {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background: url("@/assets/img/hdbjr.png") no-repeat; */
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
}

.conston-bottom {
  float: left;
  width: 100%;
  height: 13%;
  /*  ; */
  /* position: relative;
  top: -2462px;
  left: 10px;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
}

.textsty {
  /* 字体居中对齐 */
  width: 60%;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 800;
  font-size: 45px;
  color: #ffffff;
  line-height: 80px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.text-box-left {
  float: left;
}

.text-box-right {
  float: right;
}

.text-box {
  float: left;
  width: 100%;
  height: 120px;
  top: -30px;
  position: relative;
}

.conston-head {
  /* border:1px solid rgb(0, 255, 174); */

  position: relative;
  top: 13px;
  left: 700px;
  width: 61%;
  z-index: 999;
  /* height: 100%; */
}

.conston-head-left {
  float: left;
  /* border:1px solid rgb(0, 255, 174); */
  width: 560px;
  height: 300px;
  position: relative;
  top: 15px;
  background: url("@/assets/img/toukuagn.png") no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  /* toukuagn */
  /* left: 100px; */
}
.headboxsty {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 130%;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 400;
  font-size: 60px;
  color: #ffffff;
  font-style: normal;
  text-transform: none;
}

.conston-head-center {
  float: left;
  /* border:1px solid rgb(0, 255, 174); */
  width: 560px;
  height: 300px;
  position: relative;
  top: 15px;
  left: 300px;
  background: url("@/assets/img/toukuagn.png") no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
}

.conston-head-right {
  float: left;
  /* border:1px solid rgb(0, 255, 174); */
  width: 560px;
  height: 300px;
  position: relative;
  top: 15px;
  left: 585px;
  background: url("@/assets/img/toukuagn.png") no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
}

.uuwidthe {
  display: flex;
  width: 97%;
  margin: 0px auto;
}

.JZ {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#YUIO {
  /**/
  margin-top: 50px;
  text-align: center;
}

.io {
  color: rgb(9, 209, 209);
  font-size: 90px;
}

.boxy {
  width: 98%;
  height: 32%;
  margin-left: 6px;
  margin-top: 3.5%;
}

.boxy-l {
  width: 98%;
  height: 30%;
  margin-left: 6px;
  margin-top: 3.5%;
}

#inhead {
  display: flex;
  margin-top: 1.5%;
  width: 100%;
  height: 9.5%;
  /*   */
  /*  */
}

#index {
  margin: 0px auto;
  width: 100%;
  background: url("@/assets/img/bg.jpg");
}

.box-Z {
  /* display: flex;
  justify-content:space-between; */
  /*   ; */
  height: 89%;
  width: 100%;
  /* height:100vw;*/
  background: url("@/assets/img/jiaoZ.png") no-repeat;
  /*
    height: 100%; */
  background-size: 100% 100%;
}

.Rest {
  /* display: flex; */
  float: left;
  position: relative;
  top: 13px;
  left: 10px;
  width: 18%;
  height: 99%;
  /*   ; */
  /* flex-direction:column; */
  background: url("@/assets/img/zuotu28.png") no-repeat;
  /* width:100%;
    height: 100%; */
  background-size: 100% 100%;
}

.conston {
  float: left;
  position: relative;
  top: -3305px;
  left: 190px;
  width: 90.5%;
  height: 99%;
  /*  ; */
}

.Lefst {
  width: 19%;
  float: right;
  position: relative;
  top: -6630px;
  left: -10px;
  height: 99%;
  /* display: flex;
 flex-direction:column; */
  /*   ; */
  background: url("@/assets/img/youtu30.png") no-repeat;
  /* width:100%;
     */
  background-size: 100% 100%;
}
</style>
