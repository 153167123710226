<template>
  <div>
     <!-- 主要内容 -->
                    <div class="box-Z" ref="uuheight">
                        <!-- 左 -->
                        <div class="Rest">
                          <div class="boxy-l">
                            <div class="img-box">
                              <div class="headsty">资源概览</div>
                              <div class="boxy-1-bottom">
                                <div class="boxy-1-text1">
                                  <div class="textkey">GPU数量</div>
                                  <div class="textvalue">168 <span class="spansty">核</span></div>
                                  <br/>
                                  <div class="textkey" style="margin-top: 50px;">存储总量</div>
                                  <div class="textvalue">5229 <span class="spansty">TB</span></div>
                                </div>
                                <div class="boxy-1-text2">
                                  <div class="textkey" >CPU数量</div>
                                  <div class="textvalue">2560<span class="spansty"> 核</span></div>
                                  <br/>
                                  <div class="textkey" style="margin-top: 50px;">内存总量</div>
                                  <div class="textvalue">47586<span class="spansty"> GB</span></div>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="boxy-3">
                            <div class="img-box">
                              <div class="boxy-3-tusty">
                                <div v-for="(item,index) in tuarr" :key="index" style="width:100%;margin-top:0.6rem">
                                  <WaterLevelPool :ref="item.ref"></WaterLevelPool>
                                  <div class="boxy-3-tusty-text">{{item.name}}</div>
                                </div>
                              </div>


                            </div>
                          </div>
                          <div class="boxy-2">
                            <div class="img-box">
                              <div class="headsty">设备分布</div>
                              <div class="bottom-box">
                                <div style="margin-top:10px;width: 100%;height:100%;display: flex;justify-content: center">
                                  <LeftPieChart></LeftPieChart>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="boxy-4">
                            <div class="img-box">
                              <br/>
                              <div class="headsty">设备在线状态</div>
                              <div class="bottom-box1">
                                <div style="width: 90%;height:100%;display: flex;justify-content: center">
                                  <Biao1></Biao1>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="false" class="boxy-5">
                            <div class="img-box">
                              <div class="headsty">算力用量趋势</div>
                              <div class="bottom-box1">
                                <div style="margin-top:10px;width: 100%;height:100%;display: flex;justify-content: center">
                                  <LeftLineChart1></LeftLineChart1>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>

                        </div>


  </div>
</template>

<script>

import LeftPieChart from "@/components/leftmod/LeftPieChart.vue";
import Biao1 from "@/components/leftmod/Biao1.vue";
import LeftLineChart1 from "@/components/leftmod/LeftLineChart1.vue";
import WaterLevelPool from "@/components/leftmod/WaterLevelPool.vue";
export default {
  name: 'HelloWorld3',

  components: {
    LeftPieChart,
    Biao1,
    LeftLineChart1,
    WaterLevelPool
  },
  data(){
    return {
        Cheight:0,
        time:20,
        tuarr:[
          {name:"GPU使用率",ref:"bar1",value:37.34,dataarr:[0.37,0.37,0.37]},
          {name:"CPU使用率",ref:"bar2",value:56.25,dataarr:[0.56,0.56,0.56]},
          {name:"内存使用率",ref:"bar3",value:45.12,dataarr:[0.45,0.45,0.45]},
          {name:"存储使用率",ref:"bar4",value:17.41,dataarr:[0.17,0.17,0.17]},
        ]
    }
  },
  mounted(){

      this.use()
      this.datainfo()

    //  window.onresize =  ()=> {
    //   this.Cheight =document.documentElement.clientHeight-120
    //    var hg = this.$refs.uuheight
    //    hg.style.height=this.Cheight+"px";

    // };
  },
  methods:{
    use(){
        var w=1920
        this.Cheight=1080-20
        var hg = this.$refs.uuheight
        hg.style.height=this.Cheight+"px";
        // //console.log(w,this.Cheight,hg)

      },
      // 数据初始化
      datainfo(){
        for(let key in this.tuarr){
          let refs=this.tuarr[key].ref
          // console.log("this.tuarr[key].refthis.tuarr[key].ref==>",refs,this.$refs[refs])
          // 等待组件加载完成
          this.$nextTick(()=>{
            this.$refs[refs][0].echartsinit(this.tuarr[key])
          })
        }
      },
      funstart(){
        // 获得宽
             //console.log("宽度", document.documentElement.clientWidth);
        //  获得高
            //console.log("高度", document.documentElement.clientHeight);
      }
  }

}
</script>

<style type="less" scoped>

/deep/ .dv-border-box-7 .border-box-content{
  background-color: rgb(164 164 164 / 19%);
}
/deep/.boxy-3-tusty{
  /* margin-left:-4%; */
  display: flex;
  justify-content: space-around;
}

.boxy-3-tusty-text{
  width: 100%;
/*font-family: Microsoft YaHei, Microsoft YaHei;*/
font-weight: 400;
font-size: 45px;
color: #FFFFFF;
line-height: 50px;
text-align: center;
font-style: normal;
text-transform: none;
}


.headsty{
  width: 70%;
  height: 15%;
  margin-left: 70px;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 400;
  font-size: 60px;
  color: #FFFFFF;
  line-height: 130px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  letter-spacing: 3px;
}

.boxy-1-bottom{
  width: 95%;
  height: 70%;
  display:flex;
  justify-content:center;
  margin-top:0.5rem;
}
.bottom-box{
  width: 100%;
  height: 80%;
  margin-top:0.3rem;
  display:flex;
  justify-content:center;
}

.bottom-box1{
  width: 100%;
  height: 80%;

  display:flex;
  justify-content:center;
}


.textkey{
margin-top:30px;
height: 12px;
width:270px;
/*font-family: Microsoft YaHei, Microsoft YaHei;*/
font-weight: 400;
font-size: 59px;
color: #FFFFFF;
text-align: right;



}
.spansty{
  font-size: 0.2rem;
}


.textvalue{
height: 20px;
width:390px;
line-height: 240px;
/*font-family: Digital-7, Digital-7;*/
font-family: electronicFont;
font-size: 120px;
color: #FFFFFF;
text-align: right;

}

.boxy-1-text1{
  width:40%;
  height: 100%;

}
.boxy-1-text2{
  height: 100%;

}

.img-box{
    /* 设置背景图片 */
    background: url('@/assets/img/beijing.png') no-repeat;
    width:100%;
    height: 100%;
    background-size:100%  100% ;
}

.boxy-l{
  width:98%;
  height: 40%;
  margin-left: 6px;
  margin-top:  3.5%;



  /* background: rgba(112, 144, 206, 0.1); */
}


.boxy-2{
  width:98%;
  height: 35%;
  margin-left: 6px;
  margin-top:  3.5%;



}
.boxy-3{
  width:98%;
  height: 25%;
  margin-left: 6px;
  margin-top:  3.5%;

}
.boxy-4{
  width:98%;
  height: 55%;
  margin-left: 6px;
  margin-top:  3.5%;

}

.boxy-5{
  width:98%;
  height: 45%;
  margin-left: 6px;
  margin-top:  3.5%;
}

.box-Z{
  display: flex;
  justify-content:space-around;
  height:100%;

}
.Rest{
  display: flex;
  width: 100%;
  /*  ;*/
  flex-direction:column


}



</style>
