<template>
    <div style="width:100%;height:100%;">

          <div ref="echaters" style="width:100%;height:100%;"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    data(){
        return{
            echinth:null
        }
    },
   created(){

   },
   mounted(){
        this.echartsinit();


   },
   computed:{
   },
   methods:{
    //    创建echarts对象
    echartsinit(data){
        this.echinth = echarts.init((this.$refs.echaters))
        this.getdata(data);
    },
    // 请求数据
    getdata(data){

        this.upecharts(data)
    },

    //初始化对象
    upecharts(data){
      if(!data){
      return
      }
        var seriesData = data.valuearr

var optionRich = {
   a: {
      width: 60,
      fontSize: 18,
      fontWeight: 400,
      color: '#FFF',
      lineHeight: 20,
      padding: [0, 50, 0, 0]
   },
}

var optionColorArr = {
   '蓝色': 'rgba(81, 170, 225, 1)',
   '灰色': 'rgba(119, 134, 170, 1)',
  //  '较大风险': '#FF9152',
}

seriesData.forEach((ele, i) => {
   optionRich[i] = {
      width: 20,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 20,
      color: optionColorArr[ele.name],
      align: 'center',
      padding: [0, 0, 0, 20]
   }
});

 let option = {
//    backgroundColor: '#002653',
   color: seriesData.map((item) => optionColorArr[item.name]),
   title: {
      text: `{a| 业务占比 }`,
      textStyle: {
         rich: {
            a: {
               fontSize: 14,
               color: '#FFF',
            },
         },
      },
      subtext: `{a|${seriesData[0].rate + '%'}}`,
      subtextStyle: {
         rich: {
            a: {
               fontSize: 13,
               color: '#FFF',
               padding: [0, 0, 0, 0],
            },
         },
      },
      // 居中
      x: 'center',
      y: 'center',
   },
  //  tooltip: {
  //     trigger: 'item',
  //     show: true,
  //     formatter: (params) => {
  //        const { data, name, value } = params;
  //        return `
  //              <div style="color: ${params.color}; font-size: 18px;">${name}&nbsp;&nbsp;&nbsp;<span style="font-size: 20px; color: #000; float: right;">${value}</span></div>
  //              <div style="color: ${params.color}; font-size: 17px;">占比&nbsp;<span style="font-size: 20px; color: #000; float: right;">${data.rate}%</span></div>
  //           `;
  //     },
  //  },
   grid: {
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      containLabel: true
   },

   series: [
      {
         type: 'pie',
         center: ['50%', '55%'],
         radius: ['90%', '70%'],
         clockwise: true, //饼图的扇区是否是顺时针排布
         avoidLabelOverlap: false,
         label: {
          legendHoverLink:false,
            normal: {
               show: false,
            }
         },
         data: seriesData,
      }
   ]
};

        this.echinth.setOption(option, true)


        },

         getCirlPoint(x0, y0, r, angle) {
            let x1 = x0 + r * Math.cos(angle * Math.PI / 180)
            let y1 = y0 + r * Math.sin(angle * Math.PI / 180)
            return {
                x: x1,
                y: y1
            }
        },

        draw(option1,angle){
            angle = angle+3
           this.echinth.setOption(option1, true)
        //window.requestAnimationFrame(draw);
        }



   },
}




</script>
<style>

</style>
