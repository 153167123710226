<template>
  <div @mouseover="Yr" @mouseleave="Yc">
    <div class="tablesty">
      <div style="width:19.5rem;margin-top:.5rem">
        <el-table
          :data="tableData"
          ref="table"
          align="center"
          style="width: 100%"
        >
          <el-table-column label="序号" prop="id">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column label="主机名称" prop="name">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="CPU" prop="desc">
            <template slot-scope="scope">{{ scope.row.CPU }}</template>
          </el-table-column>
          <el-table-column label="内存" prop="mem">
            <template slot-scope="scope">{{ scope.row.mem }}</template>
          </el-table-column>
          <el-table-column label="存储" prop="store">
            <template slot-scope="scope">{{ scope.row.store }}</template>
          </el-table-column>
          <el-table-column label="GPU" prop="GPU">
            <template slot-scope="scope">{{ scope.row.GPU }}</template>
          </el-table-column>
          <el-table-column label="运行状态" prop="desc">
            <template slot-scope="scope">{{ scope.row.desc }}</template>
          </el-table-column>
          <el-table-column label="上线时间" prop="RunningState">
            <template slot-scope="scope">{{ scope.row.RunningState }}</template>
          </el-table-column>
          <el-table-column label="维护人员" prop="MaintenancePersonnel">
            <template slot-scope="scope">{{
              scope.row.MaintenancePersonnel
            }}</template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <div class="paginationsty">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-size="100"
      layout="total, prev, pager, next, jumper"
      :total="400">
    </el-pagination>

    </div>


    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      TotalCount:100,
      currentPage4:1,
      showis: true,
      tableData: [
        {
          id: "1",
          name: "训练服务器",
          CPU: "96C",
          mem: "2048GB",
          store: "15.36TB",
          GPU: "H100*8",
          RunningState: "运行中",
          Onlinetime: "2022-01-01",
          MaintenancePersonnel: "张三",
        },
        {
          id: "1",
          name: "训练服务器",
          CPU: "96C",
          mem: "2048GB",
          store: "15.36TB",
          GPU: "H100*8",
          RunningState: "运行中",
          Onlinetime: "2022-01-01",
          MaintenancePersonnel: "张三",
        },
        {
          id: "1",
          name: "训练服务器",
          CPU: "96C",
          mem: "2048GB",
          store: "15.36TB",
          GPU: "H100*8",
          RunningState: "运行中",
          Onlinetime: "2022-01-01",
          MaintenancePersonnel: "张三",
        },
        {
          id: "1",
          name: "训练服务器",
          CPU: "96C",
          mem: "2048GB",
          store: "15.36TB",
          GPU: "H100*8",
          RunningState: "运行中",
          Onlinetime: "2022-01-01",
          MaintenancePersonnel: "张三",
        },
        {
          id: "1",
          name: "训练服务器",
          CPU: "96C",
          mem: "2048GB",
          store: "15.36TB",
          GPU: "H100*8",
          RunningState: "运行中",
          Onlinetime: "2022-01-01",
          MaintenancePersonnel: "张三",
        },
        {
          id: "1",
          name: "训练服务器",
          CPU: "96C",
          mem: "2048GB",
          store: "15.36TB",
          GPU: "H100*8",
          RunningState: "运行中",
          Onlinetime: "2022-01-01",
          MaintenancePersonnel: "张三",
        },
        {
          id: "1",
          name: "训练服务器",
          CPU: "96C",
          mem: "2048GB",
          store: "15.36TB",
          GPU: "H100*8",
          RunningState: "运行中",
          Onlinetime: "2022-01-01",
          MaintenancePersonnel: "张三",
        },
        {
          id: "1",
          name: "训练服务器",
          CPU: "96C",
          mem: "2048GB",
          store: "15.36TB",
          GPU: "H100*8",
          RunningState: "运行中",
          Onlinetime: "2022-01-01",
          MaintenancePersonnel: "张三",
        },
        {
          id: "1",
          name: "训练服务器",
          CPU: "96C",
          mem: "2048GB",
          store: "15.36TB",
          GPU: "H100*8",
          RunningState: "运行中",
          Onlinetime: "2022-01-01",
          MaintenancePersonnel: "张三",
        },
        {
          id: "1",
          name: "训练服务器",
          CPU: "96C",
          mem: "2048GB",
          store: "15.36TB",
          GPU: "H100*8",
          RunningState: "运行中",
          Onlinetime: "2022-01-01",
          MaintenancePersonnel: "张三",
        },
      ],
    };
  },
  created() {},
  mounted() {
    //  this.Gdong()
  },
  computed: {},
  methods: {
    handleCurrentChange(){

    },
    homePageFn(){

    },
    // 鼠标移入事件
    Yr() {
      // //console.log("执行了函数...");
      clearInterval(this.numb);
    },

    // 鼠标移出事件
    Yc() {
      // //console.log("执行了移出...");
      this.Gdong();
    },
    // 实现滚动条
    Gdong() {
      const table = this.$refs.table;
      // 拿到表格中承载数据的div元素
      const divData = table.bodyWrapper;
      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
      this.numb = setInterval(() => {
        // 元素自增距离顶部1像素
        divData.scrollTop += 1;
        // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
        if (
          divData.clientHeight + divData.scrollTop >=
          divData.scrollHeight - 1
        ) {
          // 重置table距离顶部距离
          divData.scrollTop = 0;
          // this.showis=false
        }
      }, 100); // 滚动速度
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      }
  },
};
</script>
<style  scoped>
/deep/.el-pagination .btn-next {
    height:0.3rem;
    margin-left: 0.1rem;
    color: rgb(132, 172, 236);
}

/deep/.el-pagination__editor.el-input .el-input__inner {
    height: 0.3rem;
    width:0.3rem;
    color: rgb(132, 172, 236);
}

/deep/.el-pagination__editor.el-input {
    width: 0.56042rem;
}


/* 设置分页样式 */
/deep/ .el-pagination span:not([class*=suffix]), .el-pagination button {
    display: inline-block;
    font-size: 0.3rem;
    min-width: 1.39rem;
    height: 0.14583rem;
    line-height: 0.25rem;
    vertical-align: top;
    box-sizing: border-box;
    color: rgb(132, 172, 236);
}

/deep/ .el-pager li {
    padding: 0 0.02083rem;
    background: #ffffff;
    vertical-align: top;
    display: inline-block;
    font-size: 0.06771rem;
    min-width: 0.1849rem;
    height: 0.3rem;
    width: 0.3rem;
    line-height: 0.28rem;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    margin-left: 0.1rem;
    color: #0573e3;
}

/deep/ .el-pagination button:disabled {
    color: rgb(132, 172, 236);
    background-color: #ffffff;
    height: 0.3rem;
    cursor: not-allowed;
    margin-left: 0.1rem;
}


/deep/.el-pagination .btn-prev, .el-pagination .btn-next {
    background: center center no-repeat;
    background-size: 0.08333rem;
    background-color: #FFFFFF;
    cursor: pointer;
    height: 0.3rem;
    margin: 0;
    color: rgb(132, 172, 236);
}

.tablesty {
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
  width: 20.3rem;
  height: 8rem;
  background: url("@/assets/img/box3.png") no-repeat;
  background-size: 100% 100%;
}
.paginationsty {
  width: 5.5rem;
  margin-top: 0.3rem;
  float: right;
}
</style>
