<template>
    <div>

          <div ref="echaters" style="width:500px;height:125px;"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import  kf from '@/assets/img/KF.png'
export default {
    data(){
        return{
            echinth:null,
            kf
        }
    },
   created(){

   },
   mounted(){
        this.echartsinit();
        this.getdata();

   },
   computed:{
   },
   methods:{
    //    创建echarts对象
    echartsinit(){
        this.echinth = echarts.init((this.$refs.echaters))
    },
    // 请求数据
    getdata(){

        this.upecharts()
    },

    //初始化对象
    upecharts(){

var Icon={
    'centreImg':this.kf
}

var data = [
   {
      "name": "应用服务器",
      "value": 305
   },
   {
      "name": "训练服务器",
      "value": 275
   },
   {
      "name": "AI高性能存储",
      "value": 187.5
   },
   {
      "name": "推理服务器",
      "value": 232.5
   },

]


 let option = {

   color: [
      'rgb(63, 95, 191)',
      'rgb(32, 153, 226)',
      'rgb(76, 180, 241)',
      'rgb(90, 129, 250)',
      '#1888fe',
      '#43e7fe',
      '#ff454a',
      '#fde826',
      '#522eff',
      '#6d4fed',
      '#129e4c',
   ],

   title: {
      // text: `{b|分布}`,
      x: 'center',
      y: 'center',
      textStyle: {
         rich: {
            a: {
               fontSize: 22,
               color: '#FFFFFF',
            },
            b: {
               fontSize: 22,
               color: '#12D8FF',
            },
            c: {
               fontSize: 14,
               color: '#FFFFFF',
            }
         },
      },
   },

   tooltip: {
      trigger: 'item',
      show: true,
      backgroundColor: 'rgba(255, 255, 255, .16)',
      borderColor: 'transparent',
      formatter: (params) => {
         const { data, name, value } = params;
         return `
            <div style="color: ${params.color}; font-size: 18px;">${name + '：' + value}</div>
          `;

      },
   },
   emphasis: { // 设置高亮时显示标签
      scale: true, // 设置高亮时放大图形
      scaleSize: 10,
   },
   graphic: {
      elements: [
         {
            type: 'image',
            z: 3,
            style: {
               image: Icon.centreImg,
               width: 15,
               height: 15,
            },
            left: 'center',
            top: 'center',
         },
      ],
   },
   series: [
      {
         type: 'pie',
         hoverOffset: 25,
         startAngle: 180, // 起始角度
         clockwise: false, // 是否顺时针
         radius: ['50%', '70%'],
         center: ['50%', '50%'],
         avoidLabelOverlap: true,
         label: {
            show: true,
            formatter: '{b}\n ({d}%)',
            color: "#fff"
         },
         labelLine:{
            normal:{
               length:15,
               length2:40,
               lineStyle:{
                  width:1,
                  color: '#fff'
               }
            }
         },
         data: data,
         zlevel: 0,
      },
   ],
};

        this.echinth.setOption(option, true)


        },



   },
}




</script>
<style>

</style>
