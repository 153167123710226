<template>
    <div v-if="showis" @mouseover="Yr"  @mouseleave="Yc">
         <el-table
            :data="tableData"
            height="245"
            ref="table"
            align="center"
            style="width: 100%">
            <el-table-column
               label="类型"
               prop="id"
               >
               <template slot-scope="scope">{{scope.row.id}}</template>
            </el-table-column>
            <el-table-column
               label="名称"
               prop="name"
               width="110"
               >
               <template slot-scope="scope" >{{scope.row.name}}</template>
            </el-table-column>
            <el-table-column
               label="CPU"
               prop="desc">
               <template slot-scope="scope">{{scope.row.category}}</template>

            </el-table-column>
            <el-table-column
            label="内存"
            prop="desc">
            <template slot-scope="scope">{{scope.row.desc}}</template>
         </el-table-column>
      </el-table>
    </div>
</template>
<script>
export default {
   data() {
      return {
          showis:true,
          tableData: [
            {
          id: 'APP',
          name: 'gbi-test01',
          category: '12.7',
          desc: '27.42',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'LLM',
          name: 'erniebot-test01',
          category: '37.15',
          desc: '68.14',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'LLM',
          name: 'llama-test01',
          category: '53.1',
          desc: '59.33',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'GPT',
          name: 'chatglm-test01',
          category: '67.54',
          desc: '70.72',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        },{
          id: 'GPT',
          name: 'llama-test02',
          category: '78.21',
          desc: '54.32',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        },
        {
          id: 'APP',
          name: 'gbi-test01',
          category: '12.7',
          desc: '27.42',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'LLM',
          name: 'erniebot-test01',
          category: '37.15',
          desc: '68.14',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'LLM',
          name: 'llama-test01',
          category: '53.1',
          desc: '59.33',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'GPT',
          name: 'chatglm-test01',
          category: '67.54',
          desc: '70.72',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        },{
          id: 'GPT',
          name: 'llama-test02',
          category: '78.21',
          desc: '54.32',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        },
        {
          id: 'APP',
          name: 'gbi-test01',
          category: '12.7',
          desc: '27.42',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'LLM',
          name: 'erniebot-test01',
          category: '37.15',
          desc: '68.14',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'LLM',
          name: 'llama-test01',
          category: '53.1',
          desc: '59.33',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'GPT',
          name: 'chatglm-test01',
          category: '67.54',
          desc: '70.72',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        },{
          id: 'GPT',
          name: 'llama-test02',
          category: '78.21',
          desc: '54.32',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        },
        {
          id: 'APP',
          name: 'gbi-test01',
          category: '12.7',
          desc: '27.42',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'LLM',
          name: 'erniebot-test01',
          category: '37.15',
          desc: '68.14',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'LLM',
          name: 'llama-test01',
          category: '53.1',
          desc: '59.33',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }, {
          id: 'GPT',
          name: 'chatglm-test01',
          category: '67.54',
          desc: '70.72',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        },{
          id: 'GPT',
          name: 'llama-test02',
          category: '78.21',
          desc: '54.32',
          address: '上海市普陀区真北路',
          shop: 'XXXX夫妻店',
          shopId: '10333'
        }

        ]
      }
   },
   created(){
   },
   mounted(){
       this.Gdong()
   },
   computed:{

   },
   methods:{
       // 鼠标移入事件
    Yr(){
        // //console.log("执行了函数...");
        clearInterval(this.numb)

    },

    // 鼠标移出事件
    Yc(){
        // //console.log("执行了移出...");
        this.Gdong()
    },
    // 实现滚动条
    Gdong(){
         const table = this.$refs.table;
    // 拿到表格中承载数据的div元素
    const divData = table.bodyWrapper;
    // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
   this.numb=setInterval(() => {
      // 元素自增距离顶部1像素
      divData.scrollTop += 1;
      // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
      if (divData.clientHeight + divData.scrollTop >= divData.scrollHeight-1) {
        // 重置table距离顶部距离
        divData.scrollTop = 0;
        // this.showis=false
      }
    }, 100);  // 滚动速度
    },
     tableRowClassName({row, rowIndex}) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
    }
   },
}
</script>
<style>

</style>
