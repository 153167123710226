<template>
    <div style="width: 100%;height: 100%;">
       <div class="textstyle top">{{value}}</div>
       <div class="textstyle center">用量 | 总量</div>
       <div class="textstyle bottom">节点使用情况</div>
    </div>
</template>
<script>
export default {
   data() {
      return {
        value:""
      }
   },
   created(){
   },
   mounted(){
   },
   computed:{
   },
   methods:{
    getdata(data){
      this.value = data.textdata;
    }
   },
}
</script>

<style scoped>
.textstyle{
    /*  ;*/
  text-align: center;
  /*font-family: Microsoft YaHei, Microsoft YaHei;*/
  font-weight: 800;
  /* font-size: 9px; */
  color: #FFFFFF;
  line-height: 90px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.top{
    font-size: 89px;
    /* width: 360px; */
    margin-left: -20px;
}
.bottom{
    font-size: 39px;
    width: 360px;
    margin-left: 53px;
     font-weight: 600;
     letter-spacing: 1.5px;
}
.center{
    font-size: 39px;
    width: 360px;
    margin-left: 53px;
    letter-spacing: 2.5px;
     font-weight: 600;
}
</style>
